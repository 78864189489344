<template>
  <card class="p-5 mt-3 ">
    <p class="font-bold text-darkPurple text-base capitalize">
      <span class="uppercase text-sm text-romanSilver">Employee:</span>
      {{ $route.query.name }}
    </p>

    <div class=" w-full flex items-center justify-between">
      <div>
        <img class="profile-img" :src="detail.photo" v-if="detail.photo" />
        <div
          class="profile-img text-blueCrayola border text-center font-semibold py-12 text-3xl"
          v-else
        >
          {{ $getInitials(`${detail.fname} ${detail.lname}`) }}
        </div>
      </div>
      <div class="w-11/12">
        <div class="w-full h-auto flex flex-row">
          <card class="w-4/12 mr-3 fcard">
            <div class="w-full flex h-auto">
              <div class="fcard__icon">
                <icon icon-name="icon-suitcase" size="csm" />
              </div>
              <div class="w-full flex flex-col h-auto">
                <div class="fcard__header text-carrotOrange">
                  Designation
                </div>
                <div class="w-full fcard__text">
                  {{ detail ? detail.userDesignation.name : "Nil" }}
                </div>
              </div>
            </div>
          </card>

          <card class="w-4/12 mr-3 fcard">
            <div class="w-full flex h-auto">
              <div class="fcard__icon">
                <icon class="black" icon-name="icon-department" size="csm" />
              </div>
              <div class="w-full flex flex-col h-auto">
                <div class="fcard__header text-blueCrayola">
                  Function
                </div>
                <div class="w-full fcard__text">
                  {{ detail ? detail.orgFunction.name : "Nil" }}
                </div>
              </div>
            </div>
          </card>

          <card class="w-4/12 fcard">
            <div class="w-full flex h-auto">
              <div class="fcard__icon">
                <icon icon-name="icon-manager" size="csm" />
              </div>
              <div class="w-full flex flex-col h-auto">
                <div class="fcard__header" style="color: #2176FF;">
                  Line Manager
                </div>
                <div class="w-full fcard__text">
                  Odiaka Ijeoma Solomon
                </div>
              </div>
            </div>
          </card>
        </div>

        <div class="w-full h-auto flex flex-row">
          <card class="w-4/12 mr-3 fcard">
            <div class="w-full flex h-auto">
              <div class="fcard__icon">
                <icon icon-name="icon-user-list" size="csm" />
              </div>
              <div class="w-full flex flex-col h-auto">
                <div class="fcard__header text-lite">
                  Work Mail
                </div>
                <div class="w-full fcard__text">
                  {{ detail ? detail.email : "" }}
                </div>
              </div>
            </div>
          </card>

          <card class="w-4/12 mr-3 fcard">
            <div class="w-full flex h-auto">
              <div class="fcard__icon">
                <icon icon-name="pin" size="csm" />
              </div>
              <div class="w-full flex flex-col h-auto">
                <div class="fcard__header" style="color: #13B56A;">
                  Office Location
                </div>
                <div class="w-full fcard__text">
                  {{ detail ? detail.employeeOffice.name : "" }}
                </div>
              </div>
            </div>
          </card>
          <card class="w-4/12 fcard">
            <div class="w-full flex h-auto">
              <div class="fcard__icon">
                <icon icon-name="pin" size="csm" />
              </div>
              <div class="w-full flex flex-col h-auto">
                <div class="fcard__header" style="color: #13B56A;">
                  Current Status
                </div>
                <div class="w-full fcard__text">
                  {{ detail ? detail.leaveStatus : "" }}
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import Card from "@/components/Card";

export default {
  name: "EmployeeDetail",
  props: {
    detail: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    Card
  }
};
</script>
<style>
.dash-line {
  position: static;
  width: 344px;
  height: 0px;
  border: 1px solid #878e99;
  flex-grow: 1;
  margin: 0px 8px;
}
.profile-img {
  height: 135px;
  margin-top: 20px;
  margin-right: 18px;
  width: 111px;
  object-fit: cover;
  box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
  border-radius: 8px;
}
.cycle {
  border: 1px dashed #878e99;
  border-radius: 5px;
  padding: 12px;
}

.cycle__text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
  letter-spacing: 0.035em;
  height: 17px;
}

.fcard {
  padding: 12px;
  height: 58px;
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 16px;
  height: 16px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.allocate__text {
  line-height: 1.4rem;
  text-transform: uppercase;
  color: #321c3b;
}

.allocate__value {
  line-height: 17px;
  color: #ea3c53;
  margin: 3px 10px;
}

.line {
  width: 24px;
  height: 0px;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-right: 6px;
}

.line--dashed {
  border: 1px dashed #878e99;
}

.line--straight {
  border: 1px solid #878e99;
}

.kpaplus {
  width: 180px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.kpaplus__text {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.035em;
  margin: 3px 10px;
  color: #f15a29;
}

.ksm {
  margin-left: 11px;
  color: #ea3c53;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.prevgoals {
  width: 270px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.015em;
}

.prevgoals__text {
  margin: 3px 10px;
  color: #2176ff;
}

.kpalist__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #321c3b;
  width: 170px;
  height: 22px;
}

.kpalisticon {
  width: 18px;
  height: 18px;
}

.icon--edit {
  margin-left: 114px;
  padding: 3px;
}

.kpa-dashed {
  width: 730px;
  height: 0px;
  margin-top: 18px;
  margin-left: 13px;
  border: 1px dashed #878e99;
}

.chevr-right {
  padding: 2px;
  margin-left: 18px;
  margin-top: 7px;
  margin-bottom: 7px;
  cursor: pointer;
}

.button-layout {
  width: 270px;
  height: 44px;
  margin-top: 29px;
}

.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}

details > summary {
  display: inline;
  list-style: none;
}
</style>
