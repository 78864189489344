<template>
  <div class="w-full pt-5 pb-5">
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="flex">
        <back-button
          label="Back"
          @onClick="
            $router.push({
              name: 'SalaryAdvance',
              query: { type: $route.query.type }
            })
          "
          variant="secondary"
          class="mr-4"
        />
        <div
          class="mt-4"
          style="border-left: 1px solid #878E99; height:1.5rem"
        />

        <div style="">
          <h1 class="text-xl text-left font-extrabold capitalize ml-4 mt-3">
            {{ $route.query.name }}
          </h1>
        </div>

        <div class="w-7/12 mt-4 ml-4 capitalize">
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </div>
      <div class="px-3">
        <employee-detail :detail="employeeDetail" />
      </div>
      <div class="px-3">
        <card class="p-5" style="margin-top: 4px;">
          <div class="w-full flex justify-end ">
            <Button
              background-color="none"
              class="text-darkPurple ml-4 text-l"
              border="none"
              @click="openModal = true"
            >
              <div class="flex items-center">
                <icon
                  class-name="text-flame"
                  icon-name="icon-message-outline"
                  size="xsm"
                />
                <p class="ml-4 text-flame">
                  Comments
                </p>
              </div>
            </Button>
          </div>

          <div class="w-full border h-0 border-dashed" />

          <card-footer />
        </card>

        <div class="flex">
          <Table
            :headers="advanceHeader"
            :items="advanceItems"
            class="w-full mt-4 mb-4"
            :has-checkbox="false"
            :loading="false"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.type" class=" capitalize">
                {{ item.data.type }}
              </div>
              <div v-if="item.createdAt">
                {{
                  $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy")
                }}
              </div>
              <div v-else-if="item.interestRate">
                {{ `${item.data.interestRate}%` }}
              </div>
              <div v-else-if="item.principalAmount">
                {{ convertToCurrency(item.data.principalAmount) }}
              </div>
              <div v-else-if="item.interestAmount">
                {{ convertToCurrency(item.data.interestAmount) }}
              </div>
              <div v-else-if="item.repaymentAmount">
                {{ convertToCurrency(item.data.repaymentAmount) }}
              </div>
              <div class=" tw-text-center" v-else-if="item.repaymentDuration">
                {{ `${item.data.repaymentDuration}` }}
              </div>
            </template>
          </Table>
        </div>
        <!-- <card class="p-5 w-full flex">
        <div class="w-max text-lg mr-5 font-bold text-darkPurple">
          Total
        </div>
        <div
          class="w-2/12 border border-dashed mr-5 mt-4"
          style="height: 1px;"
        />
        <div class="flex w-9/12">
          <div class="w-4/12 mt-1">
            <p class="font-black uppercase text-romanSilver text-xs">
              Total Principal
            </p>
            <p class="text-lg font-bold text-darkPurple">
              333,500
            </p>
          </div>
          <div class="w-4/12 mt-1">
            <p class="font-black uppercase text-romanSilver text-xs">
              Total Interest
            </p>
            <p class="text-lg font-bold text-darkPurple">
              82,000
            </p>
          </div>
          <div class="w-4/12 mt-1">
            <p class="font-black uppercase text-romanSilver text-xs">
              Total Repayable
            </p>
            <p class="text-lg font-bold text-darkPurple">
              ₦ 480,000
            </p>
          </div>
          <div class="w-3/12 mt-1">
            <p class="font-black uppercase text-romanSilver text-xs">
              Total Paid
            </p>
            <p class="text-lg font-bold text-darkPurple">
              ₦ 310,000
            </p>
          </div>
          <div class="w-4/12 mt-1">
            <p class="font-black uppercase text-romanSilver text-xs">
              Total Outstanding
            </p>
            <p class="text-lg font-bold text-blueCrayola">
              ₦ 75,000
            </p>
          </div>
        </div>
      </card> -->
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import EmployeeDetail from "../benefitUtilities/employeeDetail";

export default {
  name: "LeaveDetails",
  components: {
    BackButton,
    Breadcrumb,
    Icon,
    Card,
    Button,
    Table,
    CardFooter,
    EmployeeDetail
  },
  data() {
    return {
      advanceHeader: [
        { title: "REQUEST TYPE", value: "type" },
        { title: "DATE REQUESTED", value: "createdAt" },
        { title: "PRINCIPAL AMNT.", value: "principalAmount" },
        { title: "INTEREST RATE (P/A)", value: "interestRate" },
        { title: "INTEREST AMNT", value: "interestAmount" },
        { title: "TOTAL REPAYABLE", value: "repaymentAmount" },
        { title: "REPAYMENT PERIOD", value: "repaymentDuration" },
        { title: "", value: "id", menu: true }
      ],
      breadcrumbs: [
        {
          disabled: false,
          text: "Benefits",
          id: ""
        },
        {
          disabled: false,
          text: "Advance",
          id: "Advance"
        },
        {
          disabled: false,
          text: this.$route.query.name,
          id: ""
        }
      ],
      advanceItems: [],
      employeeDetail: {},
      loading: true,
      moreIcon: "more_icon",
      doccycle: "doc-cycle",
      calendarIcon: "calendar",
      reviewIcon: "reviewicon",
      starreviewIcon: "star-review",
      iconInfo: "icon-info",
      iconPlus: "icon-plus",
      iconCopy: "icon-copy",
      iconEdit: "icon-edit",
      iconChevron: "chevronRight"
    };
  },
  methods: {
    async getAdvanceDetails() {
      if (this.$route.query.advanceType === "advance") {
        try {
          const {
            data: { requests, employee }
          } = await this.$_getEmployeeAdvanceAndLoan({
            id: this.$route.params.id,
            type: this.$route.query.advanceType,
            status: this.$route.query.type
          });
          this.advanceItems = requests;
          this.employeeDetail = { ...employee };
          this.loading = false;
        } catch (error) {
          throw new Error(error);
        }
      } else {
        try {
          const {
            data: { requests, employee }
          } = await this.$_getEmployeeAdvanceAndLoan({
            id: this.$route.params.id,
            type: this.$route.query.advanceType,
            status: this.$route.query.type
          });
          this.advanceItems = requests;
          this.employeeDetail = { ...employee };
          this.loading = false;
        } catch (error) {
          throw new Error(error);
        }
      }
    }
  },
  mounted() {
    this.getAdvanceDetails();
  }
};
</script>

<style>
.dash-line {
  position: static;
  width: 344px;
  height: 0px;
  border: 1px solid #878e99;
  flex-grow: 1;
  margin: 0px 8px;
}
.profile-img {
  height: 135px;
  margin-top: 20px;
  margin-right: 18px;
  width: 111px;
  object-fit: cover;
  box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
  border-radius: 8px;
}
.cycle {
  border: 1px dashed #878e99;
  border-radius: 5px;
  padding: 12px;
}

.cycle__text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
  letter-spacing: 0.035em;
  height: 17px;
}

.fcard {
  padding: 12px;
  height: 58px;
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 16px;
  height: 16px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.allocate__text {
  line-height: 1.4rem;
  text-transform: uppercase;
  color: #321c3b;
}

.allocate__value {
  line-height: 17px;
  color: #ea3c53;
  margin: 3px 10px;
}

.line {
  width: 24px;
  height: 0px;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-right: 6px;
}

.line--dashed {
  border: 1px dashed #878e99;
}

.line--straight {
  border: 1px solid #878e99;
}

.kpaplus {
  width: 180px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.kpaplus__text {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.035em;
  margin: 3px 10px;
  color: #f15a29;
}

.ksm {
  margin-left: 11px;
  color: #ea3c53;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.prevgoals {
  width: 270px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.015em;
}

.prevgoals__text {
  margin: 3px 10px;
  color: #2176ff;
}

.kpalist__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #321c3b;
  width: 170px;
  height: 22px;
}

.kpalisticon {
  width: 18px;
  height: 18px;
}

.icon--edit {
  margin-left: 114px;
  padding: 3px;
}

.kpa-dashed {
  width: 730px;
  height: 0px;
  margin-top: 18px;
  margin-left: 13px;
  border: 1px dashed #878e99;
}

.chevr-right {
  padding: 2px;
  margin-left: 18px;
  margin-top: 7px;
  margin-bottom: 7px;
  cursor: pointer;
}

.button-layout {
  width: 270px;
  height: 44px;
  margin-top: 29px;
}

.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}
</style>
